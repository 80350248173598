<template>
  <div class="flex flex-col h-screen">
    <FicheNav />
    <div v-if="error != ''" class="flex items-center flex-grow justify-center">
      <div
        class="space-x-8 flex bg-red-100 items-center border border-danger text-red-700 p-4 rounded  m-8"
      >
        <div class="block sm:inline text-danger">
          {{ error }}
        </div>
        <v-button
          block
          small
          color="secondary"
          class="text-center"
          @click="$router.go()"
        >
          Réessayer
        </v-button>
      </div>
    </div>

    <div v-else>
      <div v-if="getOneBeneficiaries">
        <div class="bg-blue-bg flex justify-between items-center">
          <div class="flex  justify-center items-center">
            <div class="flex space-x-2">
              <div>
                <a
                  v-on:click="toggle(0)"
                  v-bind:class="{
                    'font-bold bg-white border-blue-active text-blue-active':
                      current === 0
                  }"
                  class="px-6 py-3 flex border-b-4 shadow-sm"
                >
                  <div v-if="current === 1" class="pr-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.7685 4.82001C14.0085 -1.33332 4.38186 1.16825e-05 2.57519 4.35334C0.768528 8.70667 -0.0514721 8.92001 2.25519 15.1867L2.64186 15.7267C2.64186 15.7267 9.00852 8.50667 17.2219 15.92C18.4705 14.3632 19.1957 12.4519 19.2939 10.4586C19.392 8.46532 18.8582 6.49195 17.7685 4.82001ZM10.0085 10C9.34925 10 8.70479 9.80451 8.15662 9.43824C7.60846 9.07197 7.18122 8.55137 6.92893 7.94229C6.67663 7.3332 6.61062 6.66298 6.73924 6.01638C6.86786 5.36977 7.18533 4.77583 7.6515 4.30965C8.11768 3.84348 8.71162 3.52601 9.35822 3.39739C10.0048 3.26878 10.675 3.33479 11.2841 3.58708C11.8932 3.83937 12.4138 4.26661 12.7801 4.81478C13.1464 5.36294 13.3419 6.0074 13.3419 6.66668C13.3419 7.55073 12.9907 8.39858 12.3655 9.0237C11.7404 9.64882 10.8926 10 10.0085 10Z"
                        fill="#313F41"
                      />
                      <path
                        d="M10.0069 20C8.52855 19.9991 7.06879 19.6703 5.73273 19.0374C4.39667 18.4045 3.21755 17.4833 2.28026 16.34L1.93359 15.9134L2.28026 15.4934C3.21834 14.3516 4.3978 13.4319 5.73382 12.8004C7.06984 12.169 8.5292 11.8415 10.0069 11.8415C11.4846 11.8415 12.944 12.169 14.28 12.8004C15.616 13.4319 16.7955 14.3516 17.7336 15.4934L18.0803 15.9134L17.7336 16.34C16.7963 17.4833 15.6172 18.4045 14.2811 19.0374C12.9451 19.6703 11.4853 19.9991 10.0069 20ZM3.68026 15.92C4.49108 16.788 5.47168 17.4801 6.56117 17.9533C7.65066 18.4265 8.82579 18.6706 10.0136 18.6706C11.2014 18.6706 12.3765 18.4265 13.466 17.9533C14.5555 17.4801 15.5361 16.788 16.3469 15.92C15.5361 15.052 14.5555 14.36 13.466 13.8868C12.3765 13.4136 11.2014 13.1695 10.0136 13.1695C8.82579 13.1695 7.65066 13.4136 6.56117 13.8868C5.47168 14.36 4.49108 15.052 3.68026 15.92Z"
                        fill="#313F41"
                      />
                      <path
                        d="M10.0067 20C7.73517 20.0015 5.53083 19.2296 3.75647 17.8114C1.9821 16.3932 0.743444 14.4131 0.244365 12.1971C-0.254714 9.98114 0.0155245 7.66125 1.0106 5.6193C2.00568 3.57736 3.6663 1.93503 5.71914 0.96263C7.77199 -0.00976952 10.0947 -0.254295 12.3051 0.269299C14.5154 0.792892 16.4816 2.0534 17.8801 3.84337C19.2786 5.63334 20.026 7.84609 19.9993 10.1174C19.9726 12.3888 19.1735 14.5834 17.7333 16.34C16.796 17.4833 15.6169 18.4045 14.2809 19.0374C12.9448 19.6702 11.485 19.999 10.0067 20ZM10.0067 1.33334C8.29257 1.33334 6.61696 1.84163 5.19173 2.79394C3.76651 3.74624 2.65568 5.09979 1.99972 6.68341C1.34376 8.26704 1.17213 10.0096 1.50654 11.6908C1.84094 13.372 2.66636 14.9162 3.87842 16.1283C5.09047 17.3403 6.63472 18.1657 8.31589 18.5001C9.99706 18.8345 11.7396 18.6629 13.3233 18.007C14.9069 17.351 16.2604 16.2402 17.2127 14.8149C18.165 13.3897 18.6733 11.7141 18.6733 10C18.6733 7.70146 17.7602 5.49706 16.1349 3.87175C14.5096 2.24643 12.3052 1.33334 10.0067 1.33334Z"
                        fill="#313F41"
                      />
                    </svg>
                  </div>
                  <div class="pr-2 " v-else>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.7685 4.82001C14.0085 -1.33332 4.38186 1.16825e-05 2.57519 4.35334C0.768528 8.70667 -0.0514721 8.92001 2.25519 15.1867L2.64186 15.7267C2.64186 15.7267 9.00852 8.50667 17.2219 15.92C18.4705 14.3632 19.1957 12.4519 19.2939 10.4586C19.392 8.46532 18.8582 6.49195 17.7685 4.82001ZM10.0085 10C9.34925 10 8.70479 9.80451 8.15662 9.43824C7.60846 9.07197 7.18122 8.55137 6.92893 7.94229C6.67663 7.3332 6.61062 6.66298 6.73924 6.01638C6.86786 5.36977 7.18533 4.77583 7.6515 4.30965C8.11768 3.84348 8.71162 3.52601 9.35822 3.39739C10.0048 3.26878 10.675 3.33479 11.2841 3.58708C11.8932 3.83937 12.4138 4.26661 12.7801 4.81478C13.1464 5.36294 13.3419 6.0074 13.3419 6.66668C13.3419 7.55073 12.9907 8.39858 12.3655 9.0237C11.7404 9.64882 10.8926 10 10.0085 10Z"
                        fill="#1565B1"
                      />
                      <path
                        d="M10.0069 20C8.52855 19.9991 7.06879 19.6703 5.73273 19.0374C4.39667 18.4045 3.21755 17.4833 2.28026 16.34L1.93359 15.9134L2.28026 15.4934C3.21834 14.3516 4.3978 13.4319 5.73382 12.8004C7.06984 12.169 8.5292 11.8415 10.0069 11.8415C11.4846 11.8415 12.944 12.169 14.28 12.8004C15.616 13.4319 16.7955 14.3516 17.7336 15.4934L18.0803 15.9134L17.7336 16.34C16.7963 17.4833 15.6172 18.4045 14.2811 19.0374C12.9451 19.6703 11.4853 19.9991 10.0069 20ZM3.68026 15.92C4.49108 16.788 5.47168 17.4801 6.56117 17.9533C7.65066 18.4265 8.82579 18.6706 10.0136 18.6706C11.2014 18.6706 12.3765 18.4265 13.466 17.9533C14.5555 17.4801 15.5361 16.788 16.3469 15.92C15.5361 15.052 14.5555 14.36 13.466 13.8868C12.3765 13.4136 11.2014 13.1695 10.0136 13.1695C8.82579 13.1695 7.65066 13.4136 6.56117 13.8868C5.47168 14.36 4.49108 15.052 3.68026 15.92Z"
                        fill="#1565B1"
                      />
                      <path
                        d="M10.0067 20C7.73517 20.0015 5.53083 19.2296 3.75647 17.8114C1.9821 16.3932 0.743444 14.4131 0.244365 12.1971C-0.254714 9.98114 0.0155245 7.66125 1.0106 5.6193C2.00568 3.57736 3.6663 1.93503 5.71914 0.96263C7.77199 -0.00976952 10.0947 -0.254295 12.3051 0.269299C14.5154 0.792892 16.4816 2.0534 17.8801 3.84337C19.2786 5.63334 20.026 7.84609 19.9993 10.1174C19.9726 12.3888 19.1735 14.5834 17.7333 16.34C16.796 17.4833 15.6169 18.4045 14.2809 19.0374C12.9448 19.6702 11.485 19.999 10.0067 20ZM10.0067 1.33334C8.29257 1.33334 6.61696 1.84163 5.19173 2.79394C3.76651 3.74624 2.65568 5.09979 1.99972 6.68341C1.34376 8.26704 1.17213 10.0096 1.50654 11.6908C1.84094 13.372 2.66636 14.9162 3.87842 16.1283C5.09047 17.3403 6.63472 18.1657 8.31589 18.5001C9.99706 18.8345 11.7396 18.6629 13.3233 18.007C14.9069 17.351 16.2604 16.2402 17.2127 14.8149C18.165 13.3897 18.6733 11.7141 18.6733 10C18.6733 7.70146 17.7602 5.49706 16.1349 3.87175C14.5096 2.24643 12.3052 1.33334 10.0067 1.33334Z"
                        fill="#1565B1"
                      />
                    </svg>
                  </div>
                  Informations Client</a
                >
              </div>
            </div>
            <div>
              <a
                v-on:click="toggle(1)"
                v-bind:class="{
                  'font-bold bg-white border-blue-active text-blue-active':
                    current === 1
                }"
                class="px-6 py-3 flex border-b-4 shadow-sm cursor-pointer"
              >
                <div class="pr-2" v-if="current === 1">
                  <svg
                    width="15"
                    height="20"
                    viewBox="0 0 15 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.87935 2.08264C6.00492 2.02541 6.14104 1.99503 6.27902 1.99345C8.92147 2.02648 9.00074 1.91748 9.36408 2.09915L9.43014 2.12888C9.645 1.56679 9.90343 1.02233 10.2031 0.500473C10.237 0.443954 10.2533 0.37856 10.2498 0.31271C10.2463 0.24686 10.2231 0.183569 10.1833 0.130985C10.1435 0.0784012 10.0889 0.0389321 10.0265 0.017659C9.96409 -0.00361399 9.89673 -0.00571695 9.83311 0.0116209C8.9611 0.196592 8.74641 0.368351 8.37977 0.232926L8.15516 0.150349C7.85731 0.0437623 7.53311 0.035657 7.2303 0.127228L6.81082 0.256047C6.48051 0.361745 6.30215 0.21641 5.36738 0.0116209C5.30278 -0.000418583 5.23605 0.00708671 5.17573 0.0331768C5.11542 0.059267 5.06426 0.102756 5.02879 0.158081C4.99333 0.213407 4.97517 0.278054 4.97665 0.343754C4.97812 0.409454 4.99916 0.47322 5.03708 0.526897C5.40165 0.99552 5.68624 1.52119 5.87935 2.08264Z"
                      fill="#1565B1"
                    />
                    <path
                      d="M7.26421 6.28741H7.16842C6.99322 6.28741 6.82519 6.35701 6.7013 6.4809C6.57741 6.60479 6.50781 6.77282 6.50781 6.94803C6.50781 7.12323 6.57741 7.29126 6.7013 7.41515C6.82519 7.53904 6.99322 7.60864 7.16842 7.60864H7.26421V6.28741Z"
                      fill="#1565B1"
                    />
                    <path
                      d="M8.68609 8.9299C8.68609 8.75469 8.61649 8.58666 8.4926 8.46278C8.36871 8.33889 8.20068 8.26929 8.02548 8.26929H7.92969V9.59051H8.02548C8.20068 9.59051 8.36871 9.52091 8.4926 9.39702C8.61649 9.27313 8.68609 9.1051 8.68609 8.9299Z"
                      fill="#1565B1"
                    />
                    <path
                      d="M5.73264 12.256C5.80307 12.24 5.87512 12.2322 5.94734 12.2329C5.99022 12.2296 6.03328 12.2296 6.07616 12.2329L10.3933 12.8076C10.7969 12.8627 11.1681 13.0586 11.4413 13.3608C11.7145 13.663 11.8721 14.052 11.8862 14.4592C12.2532 14.3398 12.5667 14.0957 12.7724 13.7693C12.9782 13.4429 13.0631 13.0547 13.0126 12.6722L12.1108 5.91087C12.0353 5.34973 11.8236 4.8156 11.4943 4.35502C11.165 3.89444 10.728 3.5214 10.2215 3.26842L9.06873 2.69369C9.02299 2.66896 8.97207 2.65538 8.92009 2.65405H6.27765C6.22594 2.65436 6.17503 2.66681 6.12901 2.69039L4.97624 3.26512C4.46971 3.5181 4.03274 3.89114 3.70343 4.35172C3.37411 4.81229 3.16245 5.34643 3.0869 5.90756C2.14553 13.0323 2.17195 12.6755 2.17195 12.8935H2.86559L5.73264 12.256ZM7.17277 8.26925C6.82236 8.26925 6.48631 8.13005 6.23853 7.88227C5.99075 7.63449 5.85155 7.29843 5.85155 6.94802C5.85155 6.59761 5.99075 6.26156 6.23853 6.01378C6.48631 5.766 6.82236 5.6268 7.17277 5.6268H7.26856V4.82416H7.92917V5.6268H8.02496C8.37537 5.6268 8.71143 5.766 8.95921 6.01378C9.20699 6.26156 9.34619 6.59761 9.34619 6.94802H8.68557C8.68557 6.77282 8.61597 6.60479 8.49209 6.4809C8.3682 6.35701 8.20017 6.28741 8.02496 6.28741H7.92917V7.60863H8.02496C8.37537 7.60863 8.71143 7.74784 8.95921 7.99561C9.20699 8.24339 9.34619 8.57945 9.34619 8.92986C9.34619 9.28027 9.20699 9.61632 8.95921 9.8641C8.71143 10.1119 8.37537 10.2511 8.02496 10.2511H7.92917V11.0537H7.26856V10.2511H7.17277C6.82236 10.2511 6.48631 10.1119 6.23853 9.8641C5.99075 9.61632 5.85155 9.28027 5.85155 8.92986H6.51216C6.51216 9.10506 6.58176 9.27309 6.70565 9.39698C6.82954 9.52087 6.99757 9.59047 7.17277 9.59047H7.26856V8.26925H7.17277Z"
                      fill="#1565B1"
                    />
                    <path
                      d="M0.330305 17.8481H2.25929L6.17011 19.1693C6.31544 19.2156 6.00826 19.2255 11.9471 18.1784C12.0074 18.1697 12.0638 18.1433 12.109 18.1024L14.6821 15.8167C14.8648 15.6542 14.9777 15.4273 14.997 15.1834C15.0164 14.9396 14.9407 14.6978 14.7859 14.5084C14.6311 14.319 14.4091 14.1969 14.1663 14.1674C13.9235 14.1379 13.6787 14.2034 13.4831 14.3502L11.4253 15.8894L7.64988 16.5269L6.83402 16.1206L7.08505 15.8696L10.2725 15.602C10.5356 15.578 10.7801 15.456 10.9575 15.2603C11.1349 15.0645 11.2323 14.8092 11.2304 14.5451C11.2304 14.2864 11.1367 14.0365 10.9667 13.8416C10.7967 13.6466 10.5618 13.5199 10.3055 13.4848L5.98844 12.8935C5.9502 12.8867 5.91107 12.8867 5.87283 12.8935L2.93642 13.5541H0.330305C0.242703 13.5541 0.158689 13.5889 0.0967443 13.6509C0.0348 13.7128 0 13.7968 0 13.8844V17.5178C0 17.6054 0.0348 17.6894 0.0967443 17.7514C0.158689 17.8133 0.242703 17.8481 0.330305 17.8481Z"
                      fill="#1565B1"
                    />
                  </svg>
                </div>
                <div class="pr-2 " v-else>
                  <svg
                    width="15"
                    height="20"
                    viewBox="0 0 15 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.87935 2.08264C6.00492 2.02541 6.14104 1.99503 6.27902 1.99345C8.92147 2.02648 9.00074 1.91748 9.36408 2.09915L9.43014 2.12888C9.645 1.56679 9.90343 1.02233 10.2031 0.500473C10.237 0.443954 10.2533 0.37856 10.2498 0.31271C10.2463 0.24686 10.2231 0.183569 10.1833 0.130985C10.1435 0.0784012 10.0889 0.0389321 10.0265 0.017659C9.96409 -0.00361399 9.89673 -0.00571695 9.83311 0.0116209C8.9611 0.196592 8.74641 0.368351 8.37977 0.232926L8.15516 0.150349C7.85731 0.0437623 7.53311 0.035657 7.2303 0.127228L6.81082 0.256047C6.48051 0.361745 6.30215 0.21641 5.36738 0.0116209C5.30278 -0.000418583 5.23605 0.00708671 5.17573 0.0331768C5.11542 0.059267 5.06426 0.102756 5.02879 0.158081C4.99333 0.213407 4.97517 0.278054 4.97665 0.343754C4.97812 0.409454 4.99916 0.47322 5.03708 0.526897C5.40165 0.99552 5.68624 1.52119 5.87935 2.08264Z"
                      fill="#313F41"
                    />
                    <path
                      d="M7.26421 6.28741H7.16842C6.99322 6.28741 6.82519 6.35701 6.7013 6.4809C6.57741 6.60479 6.50781 6.77282 6.50781 6.94803C6.50781 7.12323 6.57741 7.29126 6.7013 7.41515C6.82519 7.53904 6.99322 7.60864 7.16842 7.60864H7.26421V6.28741Z"
                      fill="#313F41"
                    />
                    <path
                      d="M8.68609 8.9299C8.68609 8.75469 8.61649 8.58666 8.4926 8.46278C8.36871 8.33889 8.20068 8.26929 8.02548 8.26929H7.92969V9.59051H8.02548C8.20068 9.59051 8.36871 9.52091 8.4926 9.39702C8.61649 9.27313 8.68609 9.1051 8.68609 8.9299Z"
                      fill="#313F41"
                    />
                    <path
                      d="M5.73264 12.256C5.80307 12.24 5.87512 12.2322 5.94734 12.2329C5.99022 12.2296 6.03328 12.2296 6.07616 12.2329L10.3933 12.8076C10.7969 12.8627 11.1681 13.0586 11.4413 13.3608C11.7145 13.663 11.8721 14.052 11.8862 14.4592C12.2532 14.3398 12.5667 14.0957 12.7724 13.7693C12.9782 13.4429 13.0631 13.0547 13.0126 12.6722L12.1108 5.91087C12.0353 5.34973 11.8236 4.8156 11.4943 4.35502C11.165 3.89444 10.728 3.5214 10.2215 3.26842L9.06873 2.69369C9.02299 2.66896 8.97207 2.65538 8.92009 2.65405H6.27765C6.22594 2.65436 6.17503 2.66681 6.12901 2.69039L4.97624 3.26512C4.46971 3.5181 4.03274 3.89114 3.70343 4.35172C3.37411 4.81229 3.16245 5.34643 3.0869 5.90756C2.14553 13.0323 2.17195 12.6755 2.17195 12.8935H2.86559L5.73264 12.256ZM7.17277 8.26925C6.82236 8.26925 6.48631 8.13005 6.23853 7.88227C5.99075 7.63449 5.85155 7.29843 5.85155 6.94802C5.85155 6.59761 5.99075 6.26156 6.23853 6.01378C6.48631 5.766 6.82236 5.6268 7.17277 5.6268H7.26856V4.82416H7.92917V5.6268H8.02496C8.37537 5.6268 8.71143 5.766 8.95921 6.01378C9.20699 6.26156 9.34619 6.59761 9.34619 6.94802H8.68557C8.68557 6.77282 8.61597 6.60479 8.49209 6.4809C8.3682 6.35701 8.20017 6.28741 8.02496 6.28741H7.92917V7.60863H8.02496C8.37537 7.60863 8.71143 7.74784 8.95921 7.99561C9.20699 8.24339 9.34619 8.57945 9.34619 8.92986C9.34619 9.28027 9.20699 9.61632 8.95921 9.8641C8.71143 10.1119 8.37537 10.2511 8.02496 10.2511H7.92917V11.0537H7.26856V10.2511H7.17277C6.82236 10.2511 6.48631 10.1119 6.23853 9.8641C5.99075 9.61632 5.85155 9.28027 5.85155 8.92986H6.51216C6.51216 9.10506 6.58176 9.27309 6.70565 9.39698C6.82954 9.52087 6.99757 9.59047 7.17277 9.59047H7.26856V8.26925H7.17277Z"
                      fill="#313F41"
                    />
                    <path
                      d="M0.330305 17.8481H2.25929L6.17011 19.1693C6.31544 19.2156 6.00826 19.2255 11.9471 18.1784C12.0074 18.1697 12.0638 18.1433 12.109 18.1024L14.6821 15.8167C14.8648 15.6542 14.9777 15.4273 14.997 15.1834C15.0164 14.9396 14.9407 14.6978 14.7859 14.5084C14.6311 14.319 14.4091 14.1969 14.1663 14.1674C13.9235 14.1379 13.6787 14.2034 13.4831 14.3502L11.4253 15.8894L7.64988 16.5269L6.83402 16.1206L7.08505 15.8696L10.2725 15.602C10.5356 15.578 10.7801 15.456 10.9575 15.2603C11.1349 15.0645 11.2323 14.8092 11.2304 14.5451C11.2304 14.2864 11.1367 14.0365 10.9667 13.8416C10.7967 13.6466 10.5618 13.5199 10.3055 13.4848L5.98844 12.8935C5.9502 12.8867 5.91107 12.8867 5.87283 12.8935L2.93642 13.5541H0.330305C0.242703 13.5541 0.158689 13.5889 0.0967443 13.6509C0.0348 13.7128 0 13.7968 0 13.8844V17.5178C0 17.6054 0.0348 17.6894 0.0967443 17.7514C0.158689 17.8133 0.242703 17.8481 0.330305 17.8481Z"
                      fill="#313F41"
                    />
                  </svg>
                </div>
                Crédits</a
              >
            </div>
          </div>
          <!-- <div class="px-6 py-1">
              <v-button small type="button" color="secondary" class="">
                <v-icon icon="download" size="5" mr="1" />Télécharger
              </v-button>
            </div> -->
        </div>

        <keep-alive>
          <component v-bind:is="component"></component>
        </keep-alive>
      </div>
      <div class="flex h-screen w-full items-center  justify-center" v-else>
        <div class="loader flex flex-col h-full w-full "></div>
      </div>
    </div>
  </div>
</template>

<script>
import Info from '../ficheclient/infoclient.vue'
import Credit from '../ficheclient/credit.vue'
import FicheNav from '../../../../components/FicheNavBar.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('ficheClient', ['getOneBeneficiaries'])
  },
  data() {
    return {
      current: 0,
      searchFiche: '',
      ficheTable: [{ id: 0 }],
      component: 'Info',
      error: ''
    }
  },
  components: {
    Info,
    Credit,
    FicheNav
  },
  methods: {
    ...mapActions('ficheClient', ['detailsBeneficiaries']),
    toggle(index) {
      this.current = index
      this.setComponent(index)
    },
    setComponent(index) {
      if (index === 0) {
        this.component = Info
      } else {
        this.component = Credit
      }
    }
  },

  async beforeMount() {
    if (
      !this.getOneBeneficiaries ||
      this.$route.params.id != this.getOneBeneficiaries.beneficiary.id
    ) {
      try {
        await this.detailsBeneficiaries(this.$route.params.id)
      } catch (e) {
        this.error = e
      }
    }
    this.loading = false
  }
}
</script>

<style scoped>
.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #149e36; /* green */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
