<template>
  <div class="p-6">
    <div class="bg-white  rounded-xl p-5">
      <span class="font-bold">Crédits</span>
      <div class="">
        <div class="py-4 overflow-x-auto">
          <table class="text-left w-full border-collapse ">
            <thead
              class="border-b  border-t border-grey-light text-xs truncate text-gray-900 "
            >
              <tr>
                <th class=" ">N°</th>
                <th class=" ">Nom du SFD</th>
                <th class=" ">Produit</th>
                <th class=" ">Ligne de crédit</th>
                <th class=" ">Montant reçu</th>
                <th class=" ">Montant dû</th>
                <th class=" ">Montant remboursé</th>
                <th class=" "></th>
              </tr>
            </thead>
            <tbody class=" truncate border-t border-grey-light  ">
              <tr
                class=" p-4 "
                v-for="(credit, i) in getOneBeneficiaries.credits"
                :key="credit.id"
              >
                <td class="font-bold  text-xs">{{ i + 1 }}</td>

                <td class="">
                  {{
                    credit.creditDetails.credit.organization
                      ? credit.creditDetails.credit.organization.name
                      : '---'
                  }}
                </td>
                <td class="">Microcrédit Alafia</td>
                <td class="">
                  {{
                    credit.creditDetails.credit.creditLine
                      ? credit.creditDetails.credit.creditLine.name
                      : '---'
                  }}
                </td>
                <td class="">
                  {{
                    credit.creditDetails
                      ? credit.creditDetails.creditAmount
                      : '---'
                  }}
                </td>
                <td class="">
                  {{
                    credit.creditDetails
                      ? credit.creditDetails.amountDue
                      : '---'
                  }}
                </td>
                <td class="">
                  {{
                    credit.creditDetails
                      ? credit.creditDetails.amountPaid
                      : '--'
                  }}
                </td>
                <td class="underline text-blue-active">
                  <a
                    @click="detailOfOneCredit(credit)"
                    class="underline cursor-pointer "
                    >Voir détails</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters('ficheClient', ['getOneBeneficiaries'])
  },
  methods: {
    detailOfOneCredit(element) {
      console.log(element.creditDetails.credit.creditLineId, 'hh')
      this.$router.push({
        name: 'credit.details',
        params: {
          creditLineId: element.creditDetails.credit.creditLineId,
          id: this.$route.params.id
        }
      })
    }
  }
}
</script>
<style lang="css">
td,
th {
  padding: 8px;
}
</style>
