<template>
  <div class="h-screen flex flex-col w-full ">
    <div class="grid grid-cols-1 gap-6  p-6 lg:grid-cols-2 ">
      <div>
        <Toogle
          class="truncate "
          :link="imageProfil"
          :bodyTable="BodyItem"
          title="Informations ANIP"
          subtitle="Informations OTM"
          :firstCardItem="firstCardItem"
          :image="true"
          :greyCard="false"
        />
      </div>
      <div>
        <CardSimple title="Liens familiaux" :carditem="liens" />
      </div>
      <div>
        <CardSimple title="Lieu de naissance" :carditem="birth" />
      </div>
      <div>
        <CardSimple title="Adresse de résidence" :carditem="adresse" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import CardSimple from '../../../../components/InfoCard.vue'
import Toogle from '../../../../components/ToogleCard.vue'

export default {
  data() {
    return {
      loading: true
    }
  },
  components: {
    Toogle,
    CardSimple
  },
  computed: {
    ...mapGetters('ficheClient', ['getOneBeneficiaries']),
    imageProfil() {
      if (this.getOneBeneficiaries.anipInfos)
        return this.getOneBeneficiaries.anipInfos.id_photo
      return []
    },
    firstCardItem() {
      if (this.getOneBeneficiaries.anipInfos)
        return [
          {
            title: 'NPI',
            value: this.getOneBeneficiaries
              ? this.getOneBeneficiaries.anipInfos.npi
              : '---'
          },
          {
            title: 'Nom et prénoms',
            value: this.getOneBeneficiaries
              ? this.getOneBeneficiaries.anipInfos.firstname +
                ' ' +
                this.getOneBeneficiaries.anipInfos.lastname
              : '---'
          },
          {
            title: 'Numéro mobile',
            value: this.getOneBeneficiaries
              ? this.getOneBeneficiaries.anipInfos.phone_number
              : '---'
          },
          {
            title: 'Sexe',
            value: this.getOneBeneficiaries
              ? this.getOneBeneficiaries.anipInfos.sexe
              : '---'
          },
          { title: 'Commune', value: 'Mick AMANNI' },
          { title: 'Arrondissement', value: 'Mick AMANNI' },
          {
            title: 'Village',
            value: this.getOneBeneficiaries
              ? this.getOneBeneficiaries.anipInfos.residence_village
              : '---'
          }
        ]
      return []
    },
    birth() {
      if (this.getOneBeneficiaries.anipInfos)
        return [
          {
            title: 'Pays de naissance',
            value: this.getOneBeneficiaries.anipInfos
              ? this.getOneBeneficiaries.anipInfos.nationality
              : '---'
          },
          {
            title: 'Département de naissance',
            value: this.getOneBeneficiaries.anipInfos
              ? this.getOneBeneficiaries.anipInfos.birth_department
              : '---'
          },
          {
            title: 'Ville de naissance',
            value: this.getOneBeneficiaries.anipInfos
              ? this.getOneBeneficiaries.anipInfos.birth_town
              : '---'
          },
          {
            title: 'Commune de naissance',
            value: this.getOneBeneficiaries.anipInfos
              ? this.getOneBeneficiaries.anipInfos.birth_place
              : '---'
          },
          {
            title: 'Village de naissance',
            value: this.getOneBeneficiaries.anipInfos
              ? this.getOneBeneficiaries.anipInfos.birth_village
              : '---'
          }
        ]
      return []
    },

    BodyItem() {
      if (this.getOneBeneficiaries.otmInfos) {
        const profilname = JSON.parse(
          this.getOneBeneficiaries.otmInfos.metadata || null
        )?.profilename
        const profiltype = !profilname
          ? 'Non applicable'
          : profilname == 'MTNBJ Subscriber Microcredit Profile'
          ? 'Oui'
          : 'Non'
        const mobileMoney = JSON.parse(
          this.getOneBeneficiaries.otmInfos.metadata || null
        )?.subscriberstatus
        const mobileMoneyActive = mobileMoney ? mobileMoney : '-'
        return [
          {
            value: 'Nom et prénoms',
            values: this.getOneBeneficiaries.otmInfos
              ? this.getOneBeneficiaries.otmInfos.firstname +
                ' ' +
                this.getOneBeneficiaries.otmInfos.lastname
              : '---'
          },
          {
            value: 'OTM',
            values: this.getOneBeneficiaries.otmInfos
              ? this.getOneBeneficiaries.otmInfos.provider
              : '---'
          },
          { value: 'Profil micro credit', values: profiltype },
          { value: 'Compte Mobile Money', values: mobileMoneyActive }
        ]
      }

      return []
    },
    liens() {
      if (this.getOneBeneficiaries.anipInfos)
        return [
          {
            title: 'Nom du conjoint',
            value: this.getOneBeneficiaries.anipInfos
              ? this.getOneBeneficiaries.anipInfos.maritalname
              : '---'
          },
          { title: 'Nom, prénoms du père', value: '' },
          {
            title: 'NPI du père',
            value: this.getOneBeneficiaries.anipInfos
              ? this.getOneBeneficiaries.anipInfos.father_npi
              : '---'
          },
          { title: 'Nom, prénoms de la mère', value: '' },

          {
            title: 'NPI de la mère père',
            value: this.getOneBeneficiaries.anipInfos
              ? this.getOneBeneficiaries.anipInfos.mother_npi
              : '---'
          }
        ]
      return []
    },
    adresse() {
      return [
        {
          title: 'Pays de résidence',
          value: this.getOneBeneficiaries.anipInfos
            ? this.getOneBeneficiaries.anipInfos.residence_country_code
            : '---'
        },
        {
          title: 'Département de résidence',
          value: this.getOneBeneficiaries.anipInfos
            ? this.getOneBeneficiaries.anipInfos.residence_department
            : this.getOneBeneficiaries.beneficiary.state
        },
        {
          title: 'Ville de résidence',
          value: this.getOneBeneficiaries.anipInfos
            ? this.getOneBeneficiaries.anipInfos.residence_town
            : '---'
        },
        {
          title: 'Commune de résidence',
          value: this.getOneBeneficiaries.anipInfos
            ? this.getOneBeneficiaries.anipInfos.residence_town
            : this.getOneBeneficiaries.beneficiary.commune
        },
        {
          title: 'Arrondissement de résidence',
          value: this.getOneBeneficiaries.anipInfos
            ? this.getOneBeneficiaries.anipInfos.residence_district
            : this.getOneBeneficiaries.beneficiary.district
        },
        {
          title: 'Village de résidence',
          value: this.getOneBeneficiaries.anipInfos
            ? this.getOneBeneficiaries.anipInfos.residence_village
            : this.getOneBeneficiaries.beneficiary.town
        }
      ]
    }
  },
  methods: {
    ...mapActions('ficheClient', ['detailsBeneficiaries'])
  }
}
</script>

<style scoped>
.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #149e36; /* green */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
