<template>
  <div class="bg-white flex flex-col rounded-xl p-4" style="height: 320px">
    <div class="py-2 border-b-2 border-blue-active">
      <span class="font-bold text-blue-active ">{{ title }}</span>
    </div>
    <div
      v-if="carditem.length == 0"
      class="
        flex
        items-center
        justify-center
        h-full
        text-xl text-center
      text-gray-700
      "
    >
      <div class="pt-2">Aucune donnée disponible</div>
    </div>
    <div v-else>
      <div
        class="flex justify-between items-center py-2 border-b-2"
        v-for="data in carditem"
        :key="data.id"
      >
        <div class="w-1/2 ">
          <v-text as="p" size="xs">{{ data.title }}</v-text>
        </div>

        <div class="w-2/3">
          <v-text as="p" mt="1" size="" class="" weight="semibold">
            {{ data.value }}
          </v-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    carditem: Array
  }
}
</script>

<style>
hr {
  height: 2px;
}
</style>
